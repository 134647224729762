import React from "react";

export default function Header() {
  return (
    <header className="header-dashboard">
      <div className="logo-container">
        <a href="https://www.hargapedia.com.my" target="_blank">
          <img
            src="../../logo-white.svg"
            alt="Hargapedia"
            className="logo-hp"
          />
        </a>
      </div>
      <div className="byline-container">
        <span className="byline">
          Compare Price, Check Deals, Get free vouchers!
        </span>
      </div>
    </header>
  );
}
