import PriceCheapest from "../PriceCheapest";
import PriceCell from "../PriceCell";
import * as React from "react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import LinesEllipsis from "react-lines-ellipsis";
import { StaticImage } from "gatsby-plugin-image";

import useWindowSize from "../../hooks/UseWindowSize";
import { IProduct, IRetailer } from "../../@types/globals";

interface IProp {
  cheapestGradient: [string, string, string];
  products: IProduct[];
  retailers: IRetailer[];
}

const ProductRetailerTable = (props: IProp) => {
  const { cheapestGradient, products, retailers } = props;
  const size = useWindowSize();

  const hpWebUrl = "https://www.hargapedia.com.my/product";

  const cheapestCellStyle = {
    borderRight: `1px solid ${cheapestGradient[2]}`,
    background: `linear-gradient(to right, ${cheapestGradient[0]}, ${cheapestGradient[1]})`,
  };

  const isMobile = size.width < 768;

  function webAffiliateUrl(url: string) {
    if (url.includes("Product Buy Now")) {
      return url.replace("Product Buy Now", "Price Dashboard");
    }

    return url;
  }

  const TABLE_HEIGHT_DESKTOP = size.height - 250;
  const TABLE_HEIGHT_MOBILE = size.height - 140;

  // NOTE: if with CTA minus 476px height
  return (
    <div
      className="table-dashboard"
      style={{ height: isMobile ? TABLE_HEIGHT_MOBILE : TABLE_HEIGHT_DESKTOP }}
    >
      <StickyTable leftStickyColumnCount={isMobile ? 1 : 1}>
        {/* HEADER */}
        <Row>
          <Cell />

          {/* <Cell style={cheapestCellStyle}>
            <StaticImage
              placeholder="blurred"
              src="../../images/cheapest.png"
              alt="Cheapest Online!"
              height={40}
            />
          </Cell> */}

          {retailers.map((retailer) => (
            <Cell key={retailer.id}>
              {/* <div className="header-retailer">
                <div className="name">{retailer.name}</div>
              </div> */}

              <img src={retailer.logo.center} className="retailer-logo" />
            </Cell>
          ))}
        </Row>
        {products.map((product) => {
          const cheapestPrices = product.prices.reduce((acc, curr, i) => {
            if (i === 0) {
              acc.push(curr);
            } else if (acc[0] && acc[0].price === curr.price) {
              acc.push(curr);
            }
            return acc;
          }, []);
          if (product.prices.length === 0) {
            return null;
          }

          return (
            <Row key={product.id}>
              {/* <Cell></Cell> */}
              <Cell>
                <a
                  href={`${hpWebUrl}/${product.slug}`}
                  className="product"
                  target="_blank"
                >
                  <img src={product.image.small} className="product-thumb" />

                  {!isMobile ? (
                    <div className="title">{product.short_name}</div>
                  ) : (
                    <div className="title">
                      <LinesEllipsis
                        text={product.short_name}
                        maxLine="4"
                        ellipsis="..."
                        trimRight
                      />
                    </div>
                  )}
                </a>
              </Cell>
              {/* <Cell style={cheapestCellStyle}>
                <PriceCheapest
                  url={
                    cheapestPrices[0].source_type == "ONLINE"
                      ? webAffiliateUrl(cheapestPrices[0].location)
                      : `${hpWebUrl}/${product.slug}`
                  }
                  currency={cheapestPrices[0] && cheapestPrices[0].currency}
                  price={cheapestPrices[0] && cheapestPrices[0].price}
                  retailers={cheapestPrices.map((price) =>
                    retailers.find((ret) => ret.id === price.retailer.id)
                  )}
                  sourceType={cheapestPrices[0].source_type}
                />
              </Cell> */}
              {retailers.map((retailer) => {
                const priceIndex = product.prices.findIndex(
                  (e) => e.retailer && e.retailer.id === retailer.id
                );
                const price = product.prices[priceIndex];
                // console.log(price);

                if (price) {
                  return (
                    <Cell key={retailer.id}>
                      <PriceCell
                        url={
                          price.source_type == "ONLINE"
                            ? webAffiliateUrl(price.location)
                            : `${hpWebUrl}/${product.slug}`
                        }
                        currency={price.currency}
                        price={price.price}
                        location={
                          price.source_type !== "ONLINE" && price.location
                        }
                        // lowest has the higher priority
                        isLowest={cheapestPrices.find(
                          (cp) => cp.id === price.id
                        )}
                        isHighest={
                          priceIndex === product.prices.length - 1 &&
                          priceIndex !== 0
                        }
                      />
                    </Cell>
                  );
                }
                return (
                  <Cell key={retailer.id}>
                    <PriceCell
                      url={`${hpWebUrl}/${product.slug}`}
                      currency="RM"
                    />
                  </Cell>
                );
              })}
            </Row>
          );
        })}
      </StickyTable>
    </div>
  );
};

export default ProductRetailerTable;
