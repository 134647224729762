import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Download from "../components/Download";
import Explainer from "../components/Explainer";

import Loader from "../components/Loader";
import ProductRetailerTable from "../components/ProductRetailerTable";
import Helmet from "react-helmet";
import Marquee from "react-easy-marquee";
import { IProduct, IProductPrice } from "../@types/globals";
import _ from "underscore";
import * as dayjs from "dayjs";

// const EVENT_ID = "01CZ5GESFFWB2X70RYDYXCEY0R";
const EVENT_ID = "01HRC3ETACE3XBPKMNYB2GRWK7";

const API = "https://api.askhargapedia.com/graphql";

const month = dayjs().format("MMM");

const fetchEvent = async (id: string, retailers?: string[]) => {
  const query = `
  query GetEventDetailsForWeb($id: ID!, $retailers: [ID]) {
    event(id: $id, disable_targeting: true) {
      products {
        id
        short_name
        slug
        image {
          small
        }
        prices(retailers: $retailers){
          id
          retailer {
            id
            name
          }
          price
          currency
          location
          source_type
          modified_at
        }
      }
      retailers {
        id
        name
        logo {
          center
        }
      }
    }
  }
  `;
  const variables = { id, retailers };
  const response = await fetch(API, {
    method: "POST",
    // mode: 'cors',
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "5ba1d496-314f-4d3e-ad0e-4042ef8a49e9",
      "x-application-version": "minisite",
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  if (response.ok) {
    const { data, error } = await response.json();
    return { data, error };
  }
  return { data: null, error: Error("No response") };
};

export default function PriceDashboard() {
  const [products, setProducts] = useState([]);
  const [retailers, setRetailers] = useState([]);

  const getLastUpdated = (products: IProduct[]): string => {
    // get the latest date out of all the prices
    const prices: IProductPrice[] = [];
    products.forEach((p) => {
      prices.push(...p.prices);
    });

    const dates = prices.map((p) => dayjs(p.modified_at).unix());
    const final = _.sortBy(dates).reverse();
    if (final && final.length > 0) {
      const date = final[0];
      return `Last updated: ${dayjs.unix(date).format("D MMM YYYY, hh:mmA")}`;
    }
    return "";
  };

  useEffect(() => {
    fetchEvent(
      EVENT_ID,
      retailers.length > 0 ? retailers.map((e) => e.id) : null
    ).then(({ data }) => {
      if (data && data.event) {
        setProducts(data.event.products);
        if (retailers.length === 0) {
          setRetailers(data.event.retailers);
        }
      }
    });
  }, [retailers]);

  if (products.length === 0) {
    return <Loader />;
  }

  return (
    <Layout pageName="price-dashboard-main">
      <Helmet>
        <title>Harga barangan Raya 2024, {month} 2024 - Hargapedia</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/img/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/img/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/img/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/img/favicon/safari-pinned-tab.svg"
          color="#275ac7"
        />
        <link rel="shortcut icon" href="/img/favicon/favicon.ico" />
        <meta
          name="apple-mobile-web-app-title"
          content="Hargapedia Price Dashboard"
        />
        <meta name="application-name" content="Hargapedia Price Dashboard" />
        <meta name="msapplication-TileColor" content="#275ac7" />
        <meta
          name="description"
          content="Looking for the best deals on essential items for Hari Raya 2024? Look no further than Hargapedia! We have a wide range of retailers to choose from, including Mydin, Econsave, 99Speedmart, and Lotus's. Compare prices and find the best deals for your Hari Raya celebration. Save money and time by shopping with Hargapedia!"
        />
        <meta
          property="og:title"
          content="Hari Raya 2024: Check Out the Cheapest Essential Items & Compare Prices with Top Retailers - Hargapedia"
        />
        <meta
          property="og:description"
          content="Looking for the best deals on essential items for Hari Raya 2024? Look no further than Hargapedia! We have a wide range of retailers to choose from, including Mydin, Econsave, 99Speedmart, and Lotus's. Compare prices and find the best deals for your Hari Raya celebration. Save money and time by shopping with Hargapedia!"
        />
        <meta property="og:url" content="https://price.hargapedia.com.my" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Hargapedia" />
      </Helmet>

      <Header />
      {products ? (
        <ProductRetailerTable
          cheapestGradient={["#00C853", "#00C853", "#4CAF50"]}
          products={products}
          retailers={retailers}
        />
      ) : (
        <div>ERROR</div>
      )}

      <div className="disclaimer">
        <div className="desktop">
          <span className="title">Disclaimer: </span>All prices and information
          herein are verified correct at the time of upload but are subject to
          change without prior notice by respective retailers.
        </div>

        <div className="updated">{getLastUpdated(products)}</div>

        <Marquee className="marquee" duration={20000} height="16px" reverse>
          Disclaimer: All prices and information herein are verified correct at
          the time of upload but are subject to change without prior notice by
          respective retailers.
        </Marquee>
      </div>
      <div className="row center-xs">
        <div className="col-gutter-tb col-sm-10 col-xs-12">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <Banner id="8f3eed32-bc5d-11ec-adfd-02140535ce94" />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <Banner id="acf2328a-bc5d-11ec-b2c4-02140535ce94" />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <Banner id="cd0b8f26-bc5d-11ec-b5d7-02140535ce94" />
            </div>
          </div>
        </div>
      </div>

      <div className="row center-xs">
        <div className="col-sm-10 col-xs-12">
          <Explainer isMalay />
        </div>
      </div>
      <Download isMalay />
    </Layout>
  );
}
