import React from "react";

interface Props {
  isMalay?: boolean;
}

export default function Download(props: Props) {
  if (props.isMalay) {
    return (
      <div className="explainer">
        <h3>
          Bandingkan Harga Barangan Runcit, Kesihatan & Kecantikan, dan
          Elektronik di Pasaraya, Farmasi, dan Kedai Dalam Talian di Malaysia
        </h3>
        <p>
          Dengan kos sara hidup yang meningkat dan kenaikan harga secara senyap
          bagi barangan seperti barangan runcit, kita sebagai rakyat Malaysia
          perlu berbelanja dengan bijak terutamanya semasa membeli barangan
          runcit kerana ia boleh menjadi bebanan jika kita tidak mematuhi
          belanjawan kita. Terdapat pelbagai cara untuk menjimatkan belanja
          kita, salah satunya adalah dengan menggunakan aplikasi{" "}
          <a href="https://www.hargapedia.com.my">Hargapedia</a>. Selain
          membantu kita menjimatkan wang, aplikasi ini turut membantu kita
          menjimatkan masa dan tenaga semasa mencari tawaran terbaik.
        </p>

        <h3>3 Sebab Mengapa Kita Perlu Berbelanja Dengan Bijak</h3>
        <ol>
          <li>Kos keperluan asas semakin meningkat.</li>
          <li>
            Terdapat kenaikan harga secara senyap bagi barangan tertentu, dan
            pengurangan saiz barangan harian tanpa pengetahuan orang ramai.
          </li>
          <li>
            Pertumbuhan pendapatan lebih perlahan daripada inflasi yang
            menghasilkan pola perbelanjaan isi rumah yang melebihi pendapatan.
          </li>
        </ol>

        <h3>Kelebihan Menggunakan Aplikasi Hargapedia</h3>
        <ol>
          <li>Jimatkan 30% atau lebih pada barangan harian.</li>
          <li>
            Bandingkan produk kegemaran anda di antara peniaga-peniaga kegemaran
            anda.
          </li>
          <li>
            Semak tawaran terkini daripada risalah dan tawaran akhbar yang
            berbeza-beza daripada pasaraya dan farmasi seperti Lotus's, Giant,
            Mydin, 99 Speedmart, Guardian, Watsons & banyak lagi.
          </li>
          <li>
            Semak tawaran hebat daripada{" "}
            <a
              href="https://www.hargapedia.com.my/vouchers/store/shopee"
              target="_blank"
            >
              Shopee,
            </a>{" "}
            <a
              href="https://www.hargapedia.com.my/vouchers/store/lazada"
              target="_blank"
            >
              Lazada,
            </a>{" "}
            Lotus's Online,{" "}
            <a
              href="https://www.hargapedia.com.my/vouchers/store/watsons"
              target="_blank"
            >
              Watsons Online
            </a>{" "}
            & kedai-kedai dalam talian lain.
          </li>
          <li>
            Tuntut baucar percuma daripada peniaga-peniaga popular seperti{" "}
            <a
              href="https://www.hargapedia.com.my/vouchers/store/lazada"
              target="_blank"
            >
              Lazada,
            </a>{" "}
            <a
              href="https://www.hargapedia.com.my/vouchers/store/shopee"
              target="_blank"
            >
              Shopee
            </a>
            ,{" "}
            <a
              href="https://www.hargapedia.com.my/vouchers/store/foodpanda"
              target="_blank"
            >
              foodpanda,
            </a>{" "}
            <a
              href="https://www.hargapedia.com.my/vouchers/store/grab"
              target="_blank"
            >
              GrabFood,
            </a>{" "}
            dan lain-lain.
          </li>
          <li>
            Bandingkan harga produk elektronik seperti telefon pintar daripada{" "}
            <a href="https://www.hargapedia.com.my/brand/apple" target="_blank">
              Apple
            </a>
            ,{" "}
            <a
              href="https://www.hargapedia.com.my/brand/samsung"
              target="_blank"
            >
              Samsung
            </a>
            ,{" "}
            <a href="https://www.hargapedia.com.my/brand/oppo" target="_blank">
              Oppo
            </a>
            , tablet daripada{" "}
            <a
              href="https://www.hargapedia.com.my/brand/huawei"
              target="_blank"
            >
              Huawei
            </a>
            , Samsung, alat elektronik rumah seperti{" "}
            <a href="https://www.hargapedia.com.my/brand/dyson" target="_blank">
              Dyson
            </a>
            ,{" "}
            <a href="https://www.hargapedia.com.my/brand/tefal" target="_blank">
              Tefal
            </a>
            ,{" "}
            <a href="https://www.hargapedia.com.my/brand/khind" target="_blank">
              Khind
            </a>
            ,{" "}
            <a
              href="https://www.hargapedia.com.my/brand/pensonic"
              target="_blank"
            >
              Pensonic
            </a>{" "}
            & banyak lagi.
          </li>
        </ol>

        <p>
          Secara ringkasnya, tujuan utama menggunakan aplikasi Hargapedia bukan
          hanya untuk mendapatkan harga yang termurah daripada tempat yang
          berbeza-beza, tetapi juga sebagai aplikasi penjimatan yang memberitahu
          kita di mana pasaraya yang mempunyai tawaran terbaik bagi barangan
          yang kita ingin beli. Hargapedia turut boleh memberitahu kita apabila
          produk harian kegemaran kita sedang ditawarkan. Ini membantu pengguna
          memahami bahawa membeli produk harian semasa tawaran dapat membantu
          menjimatkan wang berbanding membeli ketika tiada tawaran.
        </p>
      </div>
    );
  }

  return (
    <div className="explainer">
      <h3>
        Compare Grocery, Health & Beauty & Electronic Prices at Supermarkets,
        Pharmacies, Online Stores in Malaysia
      </h3>
      <p>
        With the rising cost of living and the silent price hike of items such
        as groceries, we, as Malaysians have to spend wisely especially while
        shopping for groceries as it could be a burden if we do not shop
        strictly within our budget. In fact, there are many ways to do so, and
        one of the ways is to use the {""}
        <a href="https://www.hargapedia.com.my">Hargapedia</a> app. It does not
        only helps you save financially, but also helps in saving your time and
        energy while shopping for the best deals.
      </p>

      <h3>3 Reasons Why You Should Spend Wisely</h3>
      <ol>
        <li>The cost of basic necessities are getting higher.</li>
        <li>
          There is a silent increase in the price of goods, and a reduction in
          the size of daily goods without people noticing it.
        </li>
        <li>
          The income growth is slower than inflation which resulted in a pattern
          of household spending that exceeded income.
        </li>
      </ol>

      <h3>Benefits of Using Hargapedia App</h3>
      <ol>
        <li>Save 30% or more on daily goods.</li>
        <li>
          Compare your favourite daily essentials among your favourite
          retailers.
        </li>
        <li>
          Check the latest deals from a wide array of brochures and newspaper
          deals from supermarkets and pharmacies such as Lotus's, Giant, Mydon,
          99 Speedmart, Guardian, Watsons & many more.
        </li>
        <li>
          Check great deals and offers from{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/store/shopee"
            target="_blank"
          >
            Shopee,
          </a>{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/store/lazada"
            target="_blank"
          >
            Lazada,
          </a>{" "}
          Lotus's Online,{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/store/watsons"
            target="_blank"
          >
            Watsons Online
          </a>{" "}
          & other online stores.
        </li>
        <li>
          Claim free vouchers from popular merchants such as{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/store/lazada"
            target="_blank"
          >
            Lazada,
          </a>{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/store/shopee"
            target="_blank"
          >
            Shopee
          </a>
          ,{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/store/foodpanda"
            target="_blank"
          >
            foodpanda,
          </a>{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/store/grab"
            target="_blank"
          >
            GrabFood,
          </a>{" "}
          and so on.
        </li>
        <li>
          Compare price of electronics such as smartphones from{" "}
          <a href="https://www.hargapedia.com.my/brand/apple" target="_blank">
            Apple
          </a>
          ,{" "}
          <a href="https://www.hargapedia.com.my/brand/samsung" target="_blank">
            Samsung
          </a>
          ,{" "}
          <a href="https://www.hargapedia.com.my/brand/oppo" target="_blank">
            Oppo
          </a>
          , tablets from{" "}
          <a href="https://www.hargapedia.com.my/brand/huawei" target="_blank">
            Huawei
          </a>
          , Samsung, home appliances such as{" "}
          <a href="https://www.hargapedia.com.my/brand/dyson" target="_blank">
            Dyson
          </a>
          ,{" "}
          <a href="https://www.hargapedia.com.my/brand/tefal" target="_blank">
            Tefal
          </a>
          ,{" "}
          <a href="https://www.hargapedia.com.my/brand/khind" target="_blank">
            Khind
          </a>
          ,{" "}
          <a
            href="https://www.hargapedia.com.my/brand/pensonic"
            target="_blank"
          >
            Pensonic
          </a>{" "}
          & many more.
        </li>
      </ol>

      <p>
        In short, the main purpose of using the Hargapedia app is not just about
        getting the cheapest price from different places but acts as a one-stop
        saving app that tells you which supermarket has the most deals on the
        items that you want to purchase. Not only that, Hargapedia is also able
        to notify you when your favourite daily essential is on promotion. This
        educates users that buying daily essentials during promotion can help
        save some bucks as compared to buying them when there's no promotion.
      </p>
    </div>
  );
}
