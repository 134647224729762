import React, { useEffect, useState } from "react";
import { IBanner } from "../../@types/globals";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";
// Style
import "./style.scss";

interface Props {
  id: string;
}

// move later to proper config
const API = "https://api.askhargapedia.com/graphql";

// NOTE: mock up first. Accepts banner ID from backend to make query.
export default function Banner(props: Props) {
  const { id } = props;
  const [banner, setBanner] = useState({} as IBanner);

  const BANNER_ID = id;

  const fetchBanner = async (id: string) => {
    const query = `
    query GetBanner($id: ID!){
            banner(id: $id, disable_targeting: true) {
                id
                locale
                image_url(locale:"en")
                name(locale:"en")
                action_type
                action_value
                action_url  
            }
        }
    `;
    const variables = { id };
    const response = await fetch(API, {
      method: "POST",
      // mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "5ba1d496-314f-4d3e-ad0e-4042ef8a49e9",
        "x-application-version": "minisite",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    // console.log(response);

    if (response.ok) {
      const { data, error } = await response.json();
      return { data, error };
    }
    return { data: null, error: Error("No response") };
  };

  useEffect(() => {
    fetchBanner(BANNER_ID).then(({ data }) => {
      setBanner(data.banner);
      // console.log(data.banner);
    });
  }, []);

  if (banner.image_url === undefined) {
    return <div>No Banner</div>;
  }

  return (
    <div className="banner-container">
      <OutboundLink href={banner.action_url} target="_blank">
        <img src={banner.image_url} alt={banner.name} />
      </OutboundLink>
    </div>
  );
}
