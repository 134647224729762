import React from "react";
import { StaticImage } from "gatsby-plugin-image";

interface Props {
  isMalay?: boolean;
}

export default function Download(props: Props) {
  return (
    <div className="cta-container">
      <div className="row center-xs">
        <div className="col-sm-8 col-xs-12 col-no-gutter ">
          <div className="row">
            <div className="col-md-7 col-xs-12  download-info">
              {props.isMalay ? (
                <>
                  <h2>Aplikasi Beli-Belah yang Wajib Ada Setiap Minggu</h2>
                  <p>
                    Hargapedia membantu anda mencari jimatannya dari
                    peniaga-peniaga yang sering anda kunjungi. Muat turun secara
                    PERCUMA sekarang!
                  </p>
                </>
              ) : (
                <>
                  <h2>Your Essential Weekly Shopping App</h2>
                  <p>
                    Hargapedia helps you find the most savings from retailers
                    you frequently visit. Download for FREE now!
                  </p>
                </>
              )}

              <div className="btn-group">
                <a
                  href="https://itunes.apple.com/us/app/hargapedia/id1315874967?ls=1&mt=8"
                  target="_blank"
                >
                  <img
                    src="../../btn-apple.svg"
                    alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from the Apple App Store"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.askhargapedia.app"
                  target="_blank"
                >
                  <img
                    src="../../btn-google.svg"
                    alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from the Google Play Store"
                  />
                </a>
                <a
                  href="https://appgallery5.huawei.com/#/app/C102195777"
                  target="_blank"
                >
                  <img
                    src="../../btn-huawei.svg"
                    alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from Huawei AppGallery"
                  />
                </a>
              </div>
            </div>

            <div className="col-md-5 col-xs-12 col-no-gutter">
              <StaticImage
                placeholder="blurred"
                src="../../images/mockup-download.png"
                alt="Hargapedia App"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
